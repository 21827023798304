import React from 'react';
import SEO from "../../components/seo/Seo";
import Header from "../../components/headers/Header";
import Footer from "../../components/layouts/Footer";
import MineEventDescription from "../../components/mine-events/MineEventDescription";
import CallToAction from "../../components/layouts/CallToAction";
import MineEventsHeader from "../../components/mine-events/MineEventsHeader";

function TradicionalniDogodki(props) {
    return (
        <div>
            <SEO title="Dogodki" description="V okviru decembrskih dogodkov v mestu Litija, ki smo jih poimenovali Čarobna Litija, je pestro dogajanje tudi v in pred rudnikom Sitarjevec. Dogodki so lepo obiskani in vsako leto privabijo več obiskovalcev. Vabijo z drugačnostjo in unikatnostjo,  nekateri pa  z nostalgijo in prijetnim vzdušjem."/>
            <Header/>
            <MineEventsHeader/>
            <MineEventDescription/>
            <CallToAction/>
            <Footer/>
        </div>
    );
}

export default TradicionalniDogodki;